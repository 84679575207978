<template>
  <router-view />
</template>

<script>
import Backendless from "backendless";
export default {
  data() {
    return {};
  },
  created() {
    console.log("created log Backendless");
    Backendless.serverURL = "https://api.backendless.com";
    Backendless.initApp(
      "1B87CFC9-DC13-690E-FFA2-F557A5EF0E00",
      "42EA9536-AFCF-44F9-8212-C1B9154B124F"
    );
  },
};
</script>
<style lang="scss">
.back-top {
  background-color: #89715b;
  color: #fff;
  width: 50px;
  height: 50px;
  line-height: 50px;
  right: 50px;
  bottom: 50px;
  font-size: 14px;
  z-index: 18;
  border: 0px;
}
</style>
